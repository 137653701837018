import Subheader from '@/components/become-partner/subheader/Subheader.vue'
import BrandGuideComponent from '@/components/become-partner/BrandGuideComponent.vue'
import JoinCommunity from '@/components/become-partner/JoinCommunity.vue'
import Form from '@/components/become-partner/form/Form.vue'

export default {
  name: 'BecomePartner',
  components: {
    Subheader,
    BrandGuideComponent,
    JoinCommunity,
		Form
  },
	metaInfo () {
		return {
			title: 'Become A Partner',
			meta: [{
				name: 'description',
				content: 'Topic description about "Become A Partner" page',
			},
			{
				name: 'og:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'og:type',
				content: 'website',
			},
			{
				name: 'og:title',
				content: 'Become A Partner',
			},
			{
				name: 'og:description',
				content: 'Topic description about "Become A Partner" page',
			},
			{
				name: 'og:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			},
			{
				name: 'twitter:card',
				content: 'summary_large_image',
			},
			{
				name: 'twitter:domain',
				content: '1tn.com',
			},
			{
				name: 'twitter:url',
				content: this.createCanonicalUrl(),
			},
			{
				name: 'twitter:title',
				content: 'Become A Partner',
			},
			{
				name: 'twitter:description',
				content: 'Topic description about "Become A Partner" page',
			},
			{
				name: 'twitter:image',
				content: require('@/assets/images/charts-with-shadow.png'),
			}
		],
			link: [{
				rel: 'canonical',
				href: this.createCanonicalUrl()
			}]
		}
	},
	mounted () {
		let app = document.getElementById('app')
		if (!app) return
		app.classList.add('overflow-x-init')
	},
	beforeDestroy () {
		let app = document.getElementById('app')
		if (!app) return
		app.classList.remove('overflow-x-init')
	}
}
