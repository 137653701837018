<template>
  <div class="local-content">
    <v-row no-gutters class="flex-column mb-10 mb-md-15">
      <v-col cols="12" md="6">
        <p
					class="vue-editor text-md-h2 text-body-1 font-weight-black mb-5 mb-md-0"
					v-html="trans('become_partner_page_partner_title')"
				/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'JoinCommunity'
}
</script>

<style lang="scss" scoped>
.local-content {
  margin-top: 150px;
  margin-bottom: 150px;
}
.sub-title {
  max-width: 667px;
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .local-content {
    margin-top: 60px;
    margin-bottom: 40px;
  }
}
</style>
