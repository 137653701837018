import Form from '@/components/become-partner/form/Form.vue'

export default {
  name: 'Subheader',
  components: {
    Form
  },
	computed: {
		list () {
			return [
				{
					title: this.trans('become_partner_page_preview_list_item_1_title'),
					subtitle: this.trans('become_partner_page_preview_list_item_1_subtitle'),
				},
				{
					title: this.trans('become_partner_page_preview_list_item_2_title'),
					subtitle: this.trans('become_partner_page_preview_list_item_2_subtitle'),
				},
				{
					title: this.trans('become_partner_page_preview_list_item_3_title'),
					subtitle: this.trans('become_partner_page_preview_list_item_3_subtitle'),
				},
				{
					title: this.trans('become_partner_page_preview_list_item_4_title'),
					subtitle: this.trans('become_partner_page_preview_list_item_4_subtitle'),
				}
			]
		}
	}
}
