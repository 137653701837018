import { render, staticRenderFns } from "./BecomePartner.vue?vue&type=template&id=76c67ee9&scoped=true&"
import script from "./become-partner?vue&type=script&lang=js&"
export * from "./become-partner?vue&type=script&lang=js&"
import style0 from "./BecomePartner.vue?vue&type=style&index=0&id=76c67ee9&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76c67ee9",
  null
  
)

export default component.exports