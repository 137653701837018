<template>
  <div class="local-content">
    <v-row no-gutters class="flex-column mb-10 mb-md-15">
      <v-col cols="12">
        <p
					class="vue-editor text-md-h2 text-body-1 font-weight-black mb-5 mb-md-0"
					v-html="trans('become_partner_page_brand_title')"
				/>
      </v-col>
      <v-col cols="12" md="6">
        <p
					class="vue-editor sub-title mt-md-8 text-md-subtitle-1 text-body-2 grayText--text"
					v-html="trans('become_partner_page_brand_content')"
				/>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="d-flex">
        <BecomePartnerBtn />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BecomePartnerBtn from '@/components/become-partner/become-partner-buttons/BecomePartnerBtn'

export default {
  name: 'BrandGuideComponent',
  components: {
    BecomePartnerBtn
  }
}
</script>

<style lang="scss" scoped>
.local-content {
  margin-top: 74px;
}
.sub-title {
  max-width: 667px;
}
</style>
