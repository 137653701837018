import ApiPartnerCreateRequest from '@/api/partner-create'

export default {
	name: 'Form',
	data: () => ({
		message: '',
		errors: {},
		isFormSent: false,
		valid: false,
		form: {
			name: '',
			email: '',
			website:'',
			message: ''
		},
		sending: false
	}),
	computed: {
		nameRules () {
			return [
				v => v.length >= 2 || this.trans('become_partner_form_name_required')
			]
		},
		emailRules () {
			const emailFilter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
			return [
				v => !!v || this.trans('become_partner_form_email_is_required'),
				v => emailFilter.test(v) ||
					this.trans('become_partner_form_email_must_valid'),
				v => !(/[а-яА-ЯЁё]/.test(v)) || this.trans('become_partner_form_email_must_not_cyrillic')
			]
		},
		// urlRules () {
		// 	const urlFilter = /^(ftp|http|https):\/\/[^ "]+$/
		// 	return [
		// 		v => urlFilter.test(v) || this.trans('become_partner_form_url_must_valid'),
		// 	]
		// }
	},
	methods: {
		submit () {
			this.sending = true
			if (!this.$refs.form.validate()) return
			this.addEmails()
			ApiPartnerCreateRequest.create(this).then(response => {
				this.message = this.trans(response.data.message)
				this.sending = false
				this.isFormSent = true
			}).catch(err => {
				this.showErrors(err.response.data.errors)
			})
		},
		addEmails () {
			if (!this.state.emails && !this.state.emails.length) return
			let email = this.state.emails.find(item => item.key === 'become_a_partner')
			this.form.from = email.email_from
			this.form.admin_email = email.email_to
		},
		showErrors (errors) {
			this.errors = errors
		},
		clearError (field) {
			this.errors[field] = ''
		}
	}
}
